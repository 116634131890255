import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const Imprint = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"#1B1A19", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Imprint</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <p style={{fontWeight:"bold"}}>Imprint</p>
                <br/>Registered Office:
                <br/>Henkel Jebel Ali FZCO
                <br/>Jebel Ali Free Zone
                <br/>Lease Office Building (LB) 16
                <br/>Office 214
                <br/>
                <br/>Postal Address:
                <br/>P.O. Box 61341
                <br/>Dubai
                <br/>United Arab Emirates
                <br/>
                <br/>Main Address:
                <br/>Dubai Silicon Oasis
                <br/>Block F
                <br/>Dubai
                <br/>United Arab Emirates
                <br/>
                <br/>Email Contact:
                <br/>corporatecommunications.gcc@henkel.com
            </div>
            <Footer/>
        </div>
    )
}
