import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const AECookie = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"#1B1A19", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>ملفات تعريف الارتباط</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <div style={{marginBottom:25}}>
                   <br/> ملفات تعريف الارتباط
                   <br/> لتسهيل استخدام موقعنا الإلكتروني نستخدم "ملفات تعريف الارتباط". إن ملفات تعريف الارتباط عبارة عن وحدات صغيرة من البيانات التي يتم تخزينها مؤقتًا على القرص الصلب لحاسوبكِ عن طريق المتصفح الخاص بكِ، والتي تعدُّ ضرورية لاستخدام موقعنا الإلكتروني. لا تحتوي ملفات تعريف الارتباط على أيِّ معلومات شخصية تخصُّكِ، كما أنَّه لا يُمكن استخدامها لتحديد هوية المستخدم. وغالبًا ما يحتوي ملف تعريف الارتباط على مُعرِّف مميز، وهو عبارة عن رقم مجهول الهوية (تم إنشاؤه بشكلٍ عشوائي) وتم تخزينه على جهازك. قد تنتهي مدة بعض ملفات تعريف الارتباط عند انتهائك من استخدام الموقع الإلكتروني؛ بينما تظل ملفات أخرى مُخزَّنة على حاسوبكِ لفترة أطول.
                   <br/> أنواع ملفات تعريف الارتباط
                   <br/>
                   <br/> ملفات تعريف الارتباط الخاصة بالأطراف الأولى
                   <br/>
                   <br/> يتم تعيين ملفات تعريف الارتباط الخاصة بالأطراف الأولى بواسطة الموقع الإلكتروني الذي تقومين بزيارته، ويستطيع هذا الموقع وحده قراءة هذه الملفات.
                   <br/> ملفات تعريف الارتباط الخاصة بالأطراف الثالثة
                   <br/>
                   <br/> يتم تعيين ملفات تعريف الارتباط الخاصة بالأطراف الثالثة بواسطة مؤسسات أخرى نستعين بها لخدمات أخرى. فعلى سبيل المثال: نستخدم خدمات تحليلية خارجية حيث يقوم هؤلاء المُورِّدون بتعيين ملفات تعريف الارتباط نيابةً عنا للإبلاغ عما هو متداول وما هو غير ذلك. وقد يحتوي أيضًا الموقع الإلكتروني الذي تقوم بزيارته على محتوى مضمّن، على سبيل المثال من اليوتيوب؛ بحيث تقوم هذه المواقع بتعيين ملفات تعريف الارتباط الخاصة بها.
                   <br/> لماذا ينبغي قبول ملفات تعريف الارتباط
                   <br/>
                   <br/> تُستخدم المعلومات التي تتضمنها ملفات تعريف الارتباط من أجل تحسين الخدمات المُقدَّمة إليكِ، على سبيل المثال:
                   <br/>
                   <br/> مكين الخدمة من التعرُّف على جهازكِ حتى لا تضطرين إلى تقديم نفس المعلومات عدة مرات أثناء المهمة الواحدة، مثل: ملء نموذج الويب أو استبيان الويب.
                   <br/> دعم مكتشف المنتج.
                   <br/> السماح لمشغل الفيديو بالعمل على نحو صحيح.
                   <br/> قياس عدد الأشخاص المستخدمين للخدمات، بما يجعل هذه الخدمات أسهل من حيث الاستخدام مع إمكانية التأكُّد من سرعتها؛ تحليل البيانات مجهولة المصدر لمساعدتنا على فهم طريقة تفاعل الأشخاص مع الجوانب المختلفة لخدماتنا الإلكترونية حتى نتمكن من تحسينها.
                   <br/> ماذا يحدث إذا لم أقبل؟
                   <br/>
                   <br/> إذا قررتِ عدم قبول ملفات تعريف الارتباط، قد لا يتم عرض بعض الخصائص لهذا الموقع الإلكتروني، أو قد لا يعمل بشكلٍ صحيح؛ بحيث لا يُمكنكِ القيام بما تريدينه على موقعنا، الأمر الذي يُؤثِّر على التصميم وتجربة المستخدم.
                   <br/>
                   <br/> أمثلة على الخصائص التي قد تتأثر:
                   <br/>
                   <br/> البحث عن المنتجات والمواصفات.
                   <br/> مشاهدة مقاطع الفيديو.
                   <br/> الإعجاب بهذه الصفحة ومشاركتها على شبكات التواصل الاجتماعي.
                   <br/> عرض المحتوى المضمّن، مثل iFrames و Flas وغير ذلك.
                </div>
            </div>
            <Footer/>
        </div>
    )
}
