import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const AETerms = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"#1B1A19", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>شروط الاستخدام</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                شروط الاستخدام
            <br/>    نظرًا للابتكارات التقنية والتغيرات الطارئة باستمرار على الإطار القانوني، يجب علينا تغيير و/ أو تعديل شروط الاستخدام من حين لآخر. وعليه نطلب من المستخدم مراجعة هذه الشروط قبل كل زيارة لهذا الموقع مع مراعاة أيِّ تغييرات و/ أو تعديلات.
            <br/>
            <br/>    مقدمة
            <br/>    تُقدِّم إليكم شركة هنكل الألمانية للمواد الكيميائية والسلع الاستهلاكية (هنكل ستراس 67، دي 40589 ، دوسلدورف، ألمانيا) هذا الموقع الإلكتروني. ويتضمن الموقع جميع المعلومات المتعلقة بهنكل (المشار إليها فيما بعد باسم "شركة هنكل") والشركات التابعة لها والأطراف الأخرى، والتي قد أُعدت بعناية فائقة. ورغم ذلك لا يُمكننا ضمان اكتمال وصحة هذه المعلومات. ولا تتحمل شركة هنكل أيَّ مسؤولية بشأن أيِّ أخطاء في محتويات هذا الموقع الإلكتروني. وقد تم تقديم البيانات التطلعية حول هذا الموقع وفقًا لما لدينا من معرفة. ومع ذلك قد تختلف النتائج التي حققتها شركة هنكل في الواقع بشكلٍ كبير عن تلك البيانات التطلعية، وذلك لأنها تعتمد على مجموعة كبيرة من العوامل ذات الطبيعة التنافسية والاقتصادية الكلية، والتي تخرج في بعض الحالات عن سيطرة شركة هنكل. ودون الإخلال بأيِّ التزامات قانونية تتعلق بتعديل البيانات التطلعية؛ فإن شركة هنكل لا تنوي التحديث المستمر لجميع البيانات التطلعية التي يتضمنها هذا الموقع الإلكتروني.
            <br/>
            <br/>    بمجرد الوصول إلى هذا الموقع فإنكِ توافقين دون أيِّ قيد أو تحفُّظ على شروط الاستخدام العامَّة التالية للموقع الإلكتروني التي يُمكنكِ عرضها أو طباعتها.
            <br/>    لا يعتبر هذا الموقع الإلكتروني والمعلومات الواردة به مخصصة أو معتمدة للاستخدام داخل الولايات المتحدة الأمريكية أو من جانب المواطنين الأمريكيين أو المقيمين في الولايات المتحدة. لذا يُطلب من الأشخاص المذكورين الاتصال بالموقع الإلكتروني المحلي لشركة هنكل أو المواقع الإلكترونية الخاصة بالشركات التابعة لهنكل.
            <br/>
            <br/>    شروط الاستخدام العامة للموقع الإلكتروني
            <br/><p style={{fontWeight:"bold"}}> 1. قانون حقوق الطبع والنشر</p>
            صفحات موقعنا (المحتويات، بنية الموقع) محمية بموجب حقوق الطبع والنشر. وعلى وجه التحديد فإن النسخ والتعديلات والترجمات وعمليات التخزين والمعالجة التي تتم في الوسائط الأخرى -بما فيها التخزين والمعالجة بوسائل إلكترونية- محمية بموجب حقوق الطبع والنشر. وعليه فإن أيَّ استخدام كليٍّ أو جزئيٍّ يتطلب موافقة كتابية مُسبقة من شركة هنكل. ولإجراء أيِّ عملية نسخ للمعلومات أو البيانات، لا سيما استخدام النصوص أو أجزاء منها أو مواد مصورة (فيما عدا الصور الصحفية الخاصة بشركة هنكل) أو أي عملية استغلال أو نشر أخرى، يجب الحصول أولًا على موافقة كتابية من شركة هنكل. حقوق النشر والنسخ مملوكة لشركة هنكل.
            <br/>
            <br/>    يجوز استخدام صور هنكل الصحفية لأغراض التحرير فقط. كما يجب أن تحمل صور هنكل الصحفية التي تم نسخها و/ أو تعديلها إلكترونيًّا لأغراض التحرير إشعارًا بحقوق الطبع والنشر "© [السنة] شركة هنكل الألمانية للمواد الكيميائية والسلع الاستهلاكية. جميع الحقوق محفوظة". يجوز إعادة الطباعة مجانًا بشرط حصولنا على نسخة من الملفات الخاصة بنا.
            <br/>
            <br/>    © 2016، شركة هنكل الألمانية للمواد الكيميائية والسلع الاستهلاكية. جميع الحقوق محفوظة.
            <br/><p style={{fontWeight:"bold"}}> 2. العلامات التجارية</p>
            يعدُّ شعار هنكل البيضاوي "هنكل- التميُّز شغفنا" وجميع أسماء المنتجات و/أو المنتجات الواردة في تلك الصفحات علامات تجارية مُسجَّلة لشركة هنكل وشركاتها التابعة والشريكة وحاملي تراخيصها. يُحظر أيُّ استخدام غير مُصرَّح به أو إساءة استخدام لتلك العلامات التجارية، ويشكل خرقًا لقانون العلامات التجارية وقانون حقوق الطبع والنشر أو حقوق الملكية الفكرية الأخرى أو قانون المنافسة غير العادلة.
            <br/><p style={{fontWeight:"bold"}}>3. تصريح إخلاء المسؤولية لمواقع طرف ثالث</p>
                أ‌. تحتوي صفحات هذا الموقع الإلكتروني على روابط ("روابط تشعبية") إلى مواقع إلكترونية أخرى تُديرها أطراف ثالثة، ذات محتوى غير معلوم لشركة هنكل. تسهل هنكل الوصول إلى تلك المواقع الإلكترونية فقط، ولا تتحمل أيَّ مسؤولية كانت عن محتوياتها. يتمثل الهدف من روابطنا إلى مواقع أطراف ثالثة في تسهيل تجربتكِ في التصفح فحسب، والبيانات الواردة في تلك الصفحات لا تخصنا. وبهذا فإننا ننفي تمامًا وصراحة أيَّ علاقة لنا بذلك بأيِّ أو جميع المحتويات الوارد في صفحات الأطراف الثالثة التي ترتبط بموقعنا الإلكتروني. وبشكل خاص فإننا لا نتحمل أيَّ مسؤولية عن أيِّ خرق للتشريعات أو القوانين أو انتهاك حقوق طرف آخر في تلك الصفحات.
            <br/>
            <br/>    ب‌. فيما يتعلق بالمواقع الإلكترونية التي توجد لها روابط تشعُّبية في موقع هنكل الإلكتروني، يعدُّ مالكو تلك المواقع الإلكترونية هم وحدهم المسؤولون عن محتوى تلك الصفحات وعن أيِّ مبيعات للمنتجات المعروضة عليها وعن التعامل مع أيِّ طلبات تتعلق بها.
            <br/>
            <br/>    ج‌. لا تتحمل هنكل أيَّ مسؤولية عن أيِّ خرق لحقوق الطبع والنشر أو العلامات التجارية أو الملكية الفكرية أو أي حقوق شخصية أخرى، والتي تنشأ عن صفحة تم الوصول إليها من خلال رابط تشعُّبي.
            <br/>
            <br/>    د‌. في حال وجود طلب أو أيِّ تصريح قانوني آخر يتعلق بتعامل تجاري، يُبرم العقد فقط بين المستخدم ومالك الموقع الإلكتروني المعني أو طرف العرض أو الشخص الممثل فيه، ولا يُبرم في أيِّ حال من الأحوال بين شركة هنكل والمستخدم. يُرجى العلم بأن شروط الأعمال العامة للمورد المعني توجد على الموقع الإلكتروني في الرابط التشعُّبي.
            <br/>
            <br/>    هـ‌. يسري تصريح إخلاء المسؤولية هذا على كافة الروابط المعروضة على موقع شركة هنكل henkel.com وأيِّ محتوى في المواقع الإلكترونية التي يتم توجيه المستخدم إليها عبر تلك الروابط.
                <br/><p style={{fontWeight:"bold"}}>  4. إخلاء المسؤولية العام</p>
            يقتصر أيُّ التزام تتحمله شركة هنكل نتيجة أضرار تنشأ عن استخدام هذا الموقع الإلكتروني -وبصرف النظر عن السبب القانوني، بما في ذلك التقصير- على الإضرار قصدًا أو بسبب الإهمال الجسيم. وإلى الحد الذي تتحمل فيه شركة هنكل التزامات إجبارية نتيجة لخرق التزاماتها التعاقدية، يقتصر المبلغ الإجمالي لأيِّ مطالبة على الأضرار المتوقعة فقط، دون أن يؤثر ذلك على التزام شركة هنكل بموجب قانون التزام المنتجات المعمول به أو أيِّ ضمانات ممنوحة. كما لا تنطبق قيود الالتزام المذكورة آنفًا في حال الأضرار التي تسبب الوفاة أو الإصابات الجسدية أو الإضرار بصحة شخص.
            <br/>
            <br/>    تبذل هنكل جهودًا كبيرة للحفاظ على خلو موقع هنكل الإلكتروني من الفيروسات، ولكن لا يُمكننا ضمان خلوه التام من الفيروسات، ولهذا السبب ننصح بالعناية والتأكد من توفير الحماية الكافية ضد الفيروسات، مثل: (برامج الكشف عن الفيروسات) قبل تحميل المستندات والبيانات.
            <br/>
            <br/>    لا تضمن شركة هنكل خلو الخدمات المقدمة على موقعها الإلكتروني من العيوب أو الأخطاء أو مدى توافرها.
            <br/><p style={{fontWeight:"bold"}}>     5. التوقعات وتصريحات النوايا</p>
                تمت صياغة البيانات التطلُّعية في الموقع الإلكتروني وفقًا لما لدينا من معرفة. ومع ذلك قد تختلف النتائج التي حققتها شركة هنكل في الواقع بشكلٍ كبير عن تلك البيانات التطلُّعية، وذلك لأنها تعتمد على مجموعة كبيرة من العوامل ذات الطبيعة التنافسية والاقتصادية الكلية، والتي تخرج في بعض الحالات عن سيطرة شركة هنكل. ودون الإخلال بأيِّ التزامات قانونية تتعلق بتعديل البيانات التطلُّعية؛ فإن شركة هنكل لا تنوي التحديث المستمر لجميع البيانات التطلُّعية التي يتضمنها هذا الموقع الإلكتروني.
            <br/>
            <br/><p style={{fontWeight:"bold"}}>       6. منتجات هنكل</p>
            تُعتبر العلامات التجارية / المنتجات المعروضة على هذا الموقع الإلكتروني هي أمثلة للعلامات التجارية / المنتجات المتوفرة من شركات هنكل في جميع أنحاء العالم. ولا تضمن شركة هنكل توفُّر علامة تجارية / منتج معروض على الموقع في بلدك.
            <br/>    أحكام متفرقة
            <br/>    تخضع شروط الاستخدام العامَّة وتُفسَّر وفقًا لقوانين جمهورية ألمانيا الاتحادية، بصرف النظر عن اتفاقية الأمم المتحدة بشأن عقود البيع الدولي للبضائع، حيث يُستثنى صراحةً تطبيق الاتفاقية على هذه الشروط العامة للاستخدام. وإلى الحد المصرح بها قانونًا يكون المقر القانوني لحلِّ جميع النزاعات المُتعلقة بهذا الموقع الإلكتروني في مدينة دوسلدورف، ألمانيا. وفي حال بطلان صلاحية أيٍّ من أحكام شروط الاستخدام العامَّة، فلا يؤثر ذلك على صلاحية باقي الأحكام الأخرى.
            </div>
            <Footer/>
        </div>
    )
}
