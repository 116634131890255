import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const AEImprint = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"#1B1A19", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>طباعة</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/>برسيل جل على وسائل التواصل الاجتماعي!طباعة
                <br/>مزود الخدمة:
                <br/>
                <br/>شركة هنكل الألمانية للمواد الكيميائية والسلع الاستهلاكية
                <br/>يُمثِّلها الشريك المسؤول شخصيًّا:
                <br/>
                <br/>إدارة هنكل (Henkel Management AG)، يُمثِّلها مجلس الإدارة:
                <br/>
                <br/>كاسبر رورستيد (رئيس مجلس الإدارة)،  كارستن نوبل، سيلفي نيكول، ماركو سوبودا
                <br/>
                <br/>مارك دورن، فولفغانغ كونيغ
                <br/>
                <br/>رئيسة المجلس الإشرافي: د. سيمون باجل تراه
                <br/>
                <br/>المقر الرئيسي المسجل:
                <br/>
                <br/>هنكل ستراس 67،
                <br/>
                <br/>40589 دوسلدورف، ألمانيا
                <br/>
                <br/>العنوان البريدي:
                <br/>
                <br/>40191 دوسلدورف، ألمانيا
                <br/>
                <br/>الهاتف: 0-797-211-0049
                <br/>
                <br/>السجل التجاري:
                <br/>
                <br/>سجل محكمة دوسلدورف: HRB 4724
                <br/>
                <br/>الرقم التعريفي لضريبة القيمة المُضافة:
                <br/>
                <br/>DE 11 94 29 301
                <br/>البريد الإلكتروني:
                <a href={"mailto:corporatecommunications.gcc@henkel.com"}>corporatecommunications.gcc@henkel.com</a>
            </div>
            <Footer/>
        </div>
    )
}
