export const Card = ({item, product}) => {
    return(
        <a href={item.link} className={"card"}>
            <div style={!item.image ? {display:"none"} : {}}>
                <p style={
                    product === "nat" ?
                        {margin:0, border:"1px solid #034E1E", color:"#034E1E", fontWeight:"bold", height:20, width:"60px", textAlign:"center", paddingTop:10, paddingBottom:10, paddingLeft:0, paddingRight:0}
                        :{margin:0, border:"1px solid #FFF", fontWeight:"bold", height:20, width:"60px", textAlign:"center", paddingTop:10, paddingBottom:10, paddingLeft:0, paddingRight:0}
                }><span>{item.key}</span></p>
                <p style={
                    product === "nat" ?
                    {margin:0, width:"100%", textAlign:"right", color:"#034E1E"}
                    :{margin:0, width:"100%", textAlign:"right"}
                }>{item.label}</p>
            </div>
            <img draggable={false} src={item.image} style={!item.image ? {display:"none"} : {}}/>
        </a>
    )
}
