import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const Cookie = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"#1B1A19", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Cookies</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40, lineHeight:1.4}}>
                <br/><p style={{fontWeight:"bold"}}>Cookies</p>
                To facilitate the use of our website, we are using "cookies". Cookies are small units of data temporarily stored on the hard disk of your computer by your browser that are necessary for using our website. Cookies do not contain any personal information about you and cannot be used to identify an individual user. A cookie often includes a unique identifier, which is an anonymous number (randomly generated) and is stored on your device. Some expire at the end of your website session; others remain on your computer for longer.
                <br/><p style={{fontWeight:"bold"}}>Types of Cookie</p>
                <p style={{fontWeight:"bold"}}>First Party Cookies</p>
                First party cookies are set by the website you are visiting and they can only be read by that site.
                <p style={{fontWeight:"bold"}}>Third Party Cookies</p>Third party cookies are set by other organisations that we use for different services. For example, we use external analytics services and these suppliers  set cookies on our behalf in order to report what’s popular and what’s not. The website you are visiting may also contain content embedded from, for example, YouTube and these sites may set their own cookies.
                <p style={{fontWeight:"bold"}}>Why should I allow cookies</p>
                The information contained in cookies is used to improve services for you, for example:
                <br/>enabling a service to recognise your device so you don't have to give the same information several times during one task, e.g. filling out a web form or a web survey
                <br/>supporting the product finder
                <br/>allowing the video player to function properly
                <br/>measuring how many people are using services, so they can be made easier to use and there's enough capacity to ensure they are fast; analysing anonymised data to help us understand how people interact with the different aspects of our online services so we can make them better.
                <p style={{fontWeight:"bold"}}>What if I do not accept?</p>
                If you decide not to accept cookies, certain features of this Website may not be displayed or not be working properly. This will restrict what you can do on our site and may have an impact on design and user experience.
                <br/>Examples of features that may be affected:
                <br/>Searching for products and descriptions
                <br/>Watching Videos
                <br/>Like and share this page in social networks
                <br/>Viewing embedded content, such as iFrames, Flash etc..
                <br/>
            </div>
            <Footer/>
        </div>
    )
}
