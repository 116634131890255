import {useNavigate} from "react-router";

export const Footer = () => {
    const footer_items = [
        {label: "بيان الخصوصية", href:"/ae/policy"},
        {label: "شروط الاستخدام", href:"/ae/terms"},
        {label: "ملفات تعريف الارتباط", href:"/ae/cookies"},
        {label: "طباعة", href:"/ae/imprint"},
    ]
    const history = useNavigate();
    return(
        <div className={"footer"}>
            <footer>
                <div className={"footer-container-ar"}>
                    <div className={"footer-items-ar"}>
                        {
                            footer_items.map((item, index) => {
                                return(
                                    <a key={index} onClick={() => {history(item.href); window.scrollTo(0,0)}} style={{cursor:"pointer", color:"#1B1A19"}}>{item.label}</a>
                                )
                            })
                        }
                        <button id="ot-sdk-btn" className="ot-sdk-show-settings" style={{background:"none", fontWeight:600, color:"#1B1A19", textAlign:"right", padding:0, fontSize:14, border:"none"}}></button>
                    </div>
                    <p className={"top-line"} style={{textAlign:"center", fontSize:"14px", marginBottom:"24px", width:"100%", color:"#1B1A19"}}>©‎2024 هنكل جبل علي المنطقة الحرة</p>
                </div>
            </footer>
        </div>
    )
}
