import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const AEPrivacy = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"#1B1A19", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>بيان الخصوصية</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <p style={{fontWeight:"bold"}}>بيان الخصوصية</p>
                <p style={{fontWeight:"bold"}}>1. سياسة عامَّة</p>
                تحترم شركة هنكل الألمانية للمواد الكيميائية والسلع الاستهلاكية (يُشار إليها فيما بعد باسم "شركة هنكل") خصوصية كل شخص يزور موقعنا الإلكتروني. ونودُّ أن نُطلعكِ الآن على نوع البيانات التي تجمعها شركة هنكل وكيفية استخدامها لها. وسنُطلعكِ أيضًا على كيفية التحقق من دِقة هذه المعلومات وإجراء حذف هذه البيانات من قبل شركة هنكل. يتم جمع هذه البيانات ومعالجتها واستخدامها بما يتوافق مع لوائح حماية البيانات الخاصة بالدولة حيث تقع الإدارة المسؤولة عن معالجة البيانات. كما أننا نتخذ أيَّ إجراء لازم وضروري لضمان الامتثال لهذه اللوائح.
               <br/>
               <br/> لا ينطبق بيان الخصوصية هذا على المواقع الإلكترونية التي يمكن الوصول إليها من خلال الروابط التشعبية الموجودة على المواقع الإلكترونية التابعة لشركة هنكل.
               <br/> <p style={{fontWeight:"bold"}}>2. جمع البيانات الشخصية واستخدامها ومعالجتها</p>
                نحن نتولى جمع البيانات المتعلقة بشخص معين لأغراض المعالجة والاستخدام فقط، وذلك إذا أردتِ طوعًا إدخال البيانات أو الموافقة صراحةً على ذلك. ومن خلال قيامكِ بهذا الأمر تكونين قد وافقتِ على شروط الاستخدام التالية.
               <br/> وعند زيارتكِ لموقعنا الإلكتروني يتم تخزين بعض البيانات تلقائيًّا على الخوادم الخاصة بنا بغرض إدارة النظام أو لأغراض إحصائية أو النسخ الاحتياطي. وتشمل هذه البيانات: اسم مزود خدمة الإنترنت، وعنوان بروتوكول الإنترنت في بعض الأحيان، ونسخة البرنامج المتصفح الخاص بكِ، ونظام تشغيل الحاسوب المستخدم للوصول إلى موقعنا الإلكتروني، والموقع الإلكتروني الذي تستخدمينه لزيارتنا، والمواقع الإلكترونية التي تقومين بزيارتها أثناء زيارتكِ لموقعنا، وأي مصطلحات بحث كنتِ تستخدمينها للعثور على موقعنا الإلكتروني، إن أمكن ذلك. وحسب الظروف، قد تسمح هذه البيانات باستخلاص الاستنتاجات حول بعض زوار الموقع الإلكتروني. ومع ذلك لا يمكن استخدام أيِّ بيانات شخصية في هذا السياق، بل يتم استخدام مثل هذه البيانات فقط بعد أن يتم تقديمها دون تحديد هوية المستخدم. وفي حال قامت شركة هنكل بنقل البيانات إلى أحد مزودي الخدمة الخارجيين، سيتم اتخاذ إجراءات فنيَّة وتنظيميَّة تضمن حدوث عملية النقل بشكلٍ يتوافق مع لوائح حماية البيانات القانونية.
               <br/>
               <br/> وفي حال قدمتِ لنا البيانات الشخصية طواعيةً؛ فلن نستخدم هذه البيانات أو نعالجها أو ننقلها بشكلٍ يتجاوز الحدود التي يسمح بها القانون أو التي قمتي بتحديدها بنفسك في إقرار الموافقة الخاص بكِ. وعلاوة على ذلك سنقوم بنقل بيانتكِ فقط في حال اضطرارنا إلى ذلك بموجب أمرٍ رسميٍّ أو قضائيٍّ.
               <br/>
               <br/> وفي حال إجراء أيِّ تغييرات في بيان الخصوصية، سيتم الإعلان عنها في هذه الصفحة، بما يسمح لكِ الاطلاع في أيِّ وقت على البيانات التي نقوم بتخزينها وطريقة جمعها واستخدامها.
               <br/>
               <br/> 3. الأمن
               <br/> ستقوم شركة هنكل بتخزين بياناتكِ بأمان مع اتخاذ جميع الإجراءات الاحترازية لحمايتها ضد أيِّ خسارة أو سوء استخدام أو تغييرات. ويُعتبر شركاء هنكل التعاقديون المكلفون بالوصول إلى بياناتكِ لتقديم الخدمات إليكِ نيابة عن شركة هنكل ملتزمين تعاقديًّا بالحفاظ على سِرية مثل هذه المعلومات، ولا يجوز لهم استخدامها لأيِّ غرض آخر. وفي بعض الحالات يجب علينا تحويل استفساراتكِ إلى الشركات التابعة لهنكل. وفي هذه الحالات أيضًا سيتم التعامل مع بياناتك بسريةٍ تامَّةٍ.
               <br/>
               <br/> 4. بيانات الأطفال الشخصية
               <br/> لا ترغب شركة هنكل في جمع بيانات حول الأطفال دون سن 14 عامًا. ولكن حينما يلزم الأمر ستلفت شركة هنكل صراحةً انتباه الأطفال بطريقة مناسبة إلى ضرورة عدم إرسال أيِّ معلومات شخصية إلى شركة هنكل. وفي حال اكتشف الأبوان أو الأوصياء القانونيون الآخرون أن الأطفال -ممن هم تحت رعايتهم- قد أرسلوا بيانات شخصية إلى شركة هنكل؛ فنحن نطالبهم بالتواصل معنا على العنوان الموضح أدناه (انظر الفقرة رقم 6 أدناه)، وذلك في حال ضرورة حذف مثل هذه البيانات. وعليه سنقوم باتخاذ الإجراء اللازم لحذف هذه البيانات دون أيِّ تأخير.
               <br/>
               <br/> 5. ملفات تعريف الارتباط
               <br/> لمعرفة المزيد عن ملفات تعريف الارتباط المستخدمة في هذا الموقع يُرجى متابعة هذا الرابط.
               <br/> من خلال شركة "دبل كلك" نقوم باستخدام الخدمة التي تُقدِّمها شركة جوجل، الواقع مقرها في 1600 مدرج باركواي، ماونتن فيو، كاليفورنيا 94043، من أجل عرض الإعلانات المناسبة لكِ. ولهذا الغرض يتم استخدام ملفات تعريف الارتباط التي لا تحتوي على أيِّ معلومات شخصية. وسيُستخدم أيضًا عنوان بروتوكول الإنترنت الخاص بكِ لإنشاء ملفات تعريف الارتباط هذه. كما أن ملفات تعريف الارتباط الخاصة بشركة "دبل كلك" تستخدم رقمًا تعريفيًّا مستعارًا مخصصًا لمتصفحكِ من أجل التحقق من الإعلانات التي تظهر على الشاشة وغيرها من الإعلانات المثيرة للاهتمام. وبالتالي تستطيع مواقع جوجل وشركاؤها عرض الإعلانات بناءً على الزيارات السابقة لصفحات شركة هنكل أو المواقع الإلكترونية الأخرى. وتتولى جوجل حفظ المعلومات المجمعة من ملفات تعريف الارتباط الخاصة بشركة "دبل كلك" ونقلها إلى الخادوم.
               <br/>
               <br/> يتم نقل البيانات فقط إلى أطراف ثالثة في إطار الأحكام القانونية أو إجراءات معالجة البيانات التعاقدية. وبمجرد وصولكِ إلى الموقع الإلكتروني لشركة هنكل، فتكوني بذلك موافقة على استخدام البيانات المذكورة أعلاه وما تقوم به جوجل من إجراءات المعالجة الموصوفة سابقة. كما يُمكنكِ رفض تخزين ملفات تعريف الارتباط داخل إعدادات متصفحك. وفي حال موافقتكِ على استخدام ملفات تعريف الارتباط بشكلٍ عام، ولكنكِ لا تفضلين استخدام ملفات تعريف الارتباط الخاصة بشركة "دبل كلك"، يمكنكِ اتباع هذا الرابط (https://tools.google.com/dlpage/gaoptout) وتنزيل برنامج مساعد لمتصفحكِ وتثبيته لإلغاء تفعيل خدمة "دبل كلك" التابعة لجوجل.
               <br/>
               <br/> 6. تتبُّع الويب
               <br/> يجمع هذا الموقع الإلكتروني البيانات ويُخزِّنها لأغراض التسويق والتحسين.
               <br/>
               <br/> يُمكن استخدام هذه البيانات لإنشاء ملفات شخصية للمستخدمين بأسماء مستعارة. ولهذا الغرض نستخدم ملفات تعريف الارتباط التي تسمح لنا بالتعرُّف على متصفح الويب الخاص بكِ.
               <br/>
               <br/> يتم نقل المعلومات الخاصة باستخدام الموقع الإلكتروني، الذي يتم إنشاؤه بواسطة ملفات تعريف الارتباط، وتخزينها على أحد الخوادم التي تشغلها شركة جوجل في الولايات المتحدة الأمريكية. لا تُستخدم هذه البيانات لتحديد هويتكِ الشخصية، ويتم استكمالها ببياناتكِ الشخصية.
               <br/>
               <br/> ستتولى جوجل عملية اقتطاع عنوان بروتوكول الإنترنت الخاص بكِ داخل الدول الأعضاء في الاتحاد الأوروبي ودول المنطقة الاقتصادية الأوروبية الأخرى، وذلك قبل نقلها داخل الولايات المتحدة. ولكن في بعض الحالات الاستثنائية فقط سيتم نقل عنوان بروتوكول الإنترنت بالكامل إلى الخوادم التابعة لجوجل في الولايات المتحدة حيث سيتم اقتطاعها لاحقًا.
               <br/>
               <br/> يحق لكِ إلغاء موافقتكِ على جمع وتخزين البيانات في أيِّ وقتٍ بأثرٍ مستقبلي.
               <br/>
               <br/> ويتطلب ذلك أن تقومي بتثبيت امتداد إضافي من جوجل، والذي يمكنكِ تنزيله من هنا.
               <br/>
               <br/> وبمجرد تثبيت هذا الامتداد الإضافي على متصفحكِ، فإنه يُرسل إخطارًا إلى كود جافا سكريبت بعدم نقل أيِّ معلومات عن زيارتكِ للموقع الإلكتروني إلى تحليلات جوجل.
               <br/>
               <br/> وبدلًا من تثبيت الامتداد الإضافي على المتصفح أو الأجهزة المحمولة؛ يُمكنكِ استخدام هذا الرابط لمنع التسجيل المستقبلي للبيانات على هذا الموقع الإلكتروني (ينطبق هذا الانسحاب فقط على المتصفح الحالي وفي إطار هذا النطاق فقط). تعمل هذه الطريقة على تخزين ملف تعريف الارتباط الخاص بالانسحاب على جهازكِ. وإذا حذفتِ ملفات تعريف الارتباط الموجودة على متصفحكِ؛ فستضطرين إلى استخدام الرابط من جديد.
               <br/>
               <br/> يُرجى الرجوع إلى سياسة خصوصية البيانات الخاصة بجوجل للاطلاع على مزيد من المعلومات حول تتبُّع الويب.
               <br/>
               <br/> Adobe Analytics
               <br/>
               <br/> شريطة موافقتك ، يستخدم هذا الموقع Adobe Analytics ، وهي خدمة تحليلات ويب تقدمها Adobe Systems Software Ireland Limited (4-6 Riverwalk ، City West Business Campus ، Saggart ، دبلن 24 ، أيرلندا) ("Adobe"). لهذا الغرض ، يتم تثبيت ملف تعريف ارتباط على جهازك. سيجمع ملف تعريف الارتباط هذا بيانات مثل معلومات المتصفح والجهاز ، وعنوان IP الخاص بك ، والمواقع التي تمت زيارتها ، وتاريخ ووقت طلب الخادم لغرض تقييم استخدامك للموقع ، وتجميع التقارير عن نشاط الموقع لمشغلي موقع الويب ، وتقديم الخدمات الأخرى المتعلقة لنشاط موقع الويب واستخدام الإنترنت لمزود موقع الويب.
               <br/>
               <br/> قد تستخدم Adobe (بصفتها جهة تحكم) بياناتك لأية أغراض خاصة مثل التنميط وربط بياناتك ببيانات أخرى تم جمعها عنك (على سبيل المثال من خلال حساب Adobe الخاص بك).
               <br/>
               <br/> سيتم نقل المعلومات التي تم إنشاؤها بواسطة ملف تعريف الارتباط حول استخدامك للموقع (بما في ذلك عنوان IP الخاص بك) إلى Adobe ومعالجتها على خوادم في المملكة المتحدة. بالنسبة لنقل بيانات هذا البلد الثالث ، يتم ضمان مستوى مناسب من حماية البيانات من خلال البنود التعاقدية القياسية ، الفن. 46 القانون العام لحماية البيانات (GDPR) ، في انتظار قرار الملاءمة من قبل المفوضية الأوروبية (المادة 45 من اللائحة العامة لحماية البيانات).
               <br/>
               <br/> لقد قمنا بتنشيط إخفاء هوية IP ، مما يعني أن آخر ثماني بتات (الجزء الأخير) من عنوان IP يتم إخفاؤها على الفور عندما يتم جمع عنوان IP بواسطة Adobe. يتم إجراء هذا إخفاء الهوية قبل أي معالجة لعنوان IP. لن يتوفر سوى موقع تقريبي لغرض التحليل الإحصائي. بعد البحث الجغرافي ، يتم إخفاء عناوين IP في كل مكان في Analytics - تم استبدالها بـ :: X.X.X.X.
               <br/>
               <br/> بالسماح لملفات تعريف الارتباط على موقع هنكل ، فإنك توافق على استخدام البيانات المذكورة أعلاه والمعالجة الموصوفة سابقًا بواسطة Adobe.
               <br/>
               <br/>
               <br/>
               <br/> يمكنك العثور على مزيد من المعلومات حول الخصوصية في خدمات Adobe هنا: https://www.adobe.com/privacy/policy.html
               <br/>
               <br/> الاعتراض على جمع البيانات:
               <br/>
               <br/>
               <br/>
               <br/> يمكنك سحب موافقتك في أي وقت بأثر للمستقبل عن طريق تعطيل ملفات تعريف الارتباط على موقعنا الإلكتروني ضمن "ملفات تعريف الارتباط" في "إعدادات ملفات تعريف الارتباط". إذا كنت توافق على استخدام ملفات تعريف الارتباط بشكل عام ، ولكنك غير مرتاح لاستخدام ملفات تعريف الارتباط من Adobe Analytics ، يمكنك اتباع هذا الرابط (https://www.adobe.com/privacy/opt-out.html) و قم بإلغاء تنشيط جمع Adobe Analytics واستخدام البيانات (ملفات تعريف الارتباط وعنوان IP).
               <br/>
               <br/> 7. رغباتكِ واستفساراتكِ/ مسؤول حماية البيانات
               <br/> سيتم حذف البيانات المُخزَّنة من قِبل شركة هنكل بمجرد انتهاء فترة التقديم القانونية أو التعاقدية، أو في حال لم تعد شركة هنكل تحتاج إلى هذه البيانات. ويحق لكِ في أيِّ وقت بالطبع أن تطلبي حذف بياناتكِ. ويحق لكِ أيضًا في أيِّ وقت أن تلغي موافقتك على استخدام أو معالجة بياناتكِ الشخصية بأثر مستقبلي. وفي هذه الحالات أو في حال كانت لديكِ رغبات أخرى تتعلق ببياناتكِ الشخصية، يرجى إرسال رسالة بريد إلكتروني أو خطاب إلى مسؤول حماية البيانات لدينا، السيد/ فرانك ليبيش، هنكل ستراس 67، دي 40191، دوسلدورف، ألمانيا (البريد الإلكتروني: Datenschutz@henkel.com). ويرجى أيضًا التواصل معنا بهذه الطريقة إذا أردتِ معرفة ما إذا قمنا بجمع بياناتكِ أم لا، وما هي هذه البيانات في حال قمنا بجمعها. وسنسعى جاهدين إلى الامتثال لرغبتكِ على الفور.
               <br/>
               <br/> 8. السجل العام للمعالجة
               <br/> ينص قانون حماية البيانات الفيدرالي (الفقرة رقم 4ز) على أن يقوم مسؤول حماية البيانات بإتاحة البيانات التالية لأيِّ شخص بطريقة مناسبة، وذلك وفقًا للفقرة رقم (4هـ).
               <br/>
               <br/> (1) اسم الجهة المسؤولة:
               <br/>
               <br/> شركة هنكل الألمانية للمواد الكيميائية والسلع الاستهلاكية
               <br/>
               <br/> (2) الشريك المسؤول شخصيًّا / مجلس الإدارة: شركة هنكل (Henkel Management AG)
               <br/>
               <br/> كارستن كنوبل (رئيس مجلس الإدارة)
               <br/> سيلفي نيكول
               <br/> ماركو سوبودا
               <br/> فولفغانغ كونيغ
               <br/> مارك دورن
               <br/> نائب أول رئيس مجلس إدارة الشركة لمعالجة البيانات:
               <br/> يواكيم جاكلي
               <br/>
               <br/>
               <br/> (3) عنوان الجهة المسؤولة: 40191  دوسلدورف، ألمانيا
               <br/>
               <br/> (4) الغرض المقصود من جمع البيانات أو معالجتها أو استخدامها:
               <br/>
               <br/> يتمثل هدف الشركة في تصنيع وتوزيع المنتجات الكيميائية بجميع أنواعها، خاصةً:
               <br/>
               <br/> المُنظِّفات، ومواد التطهير، ومنتجات العناية الشخصية، والمواد الخام الكيميائية، والمواد اللاصقة والمواد الكيميائية الصناعية.
               <br/> منتجات العناية الشخصية، ومستحضرات التجميل، والمنتجات الصيدلانية.
               <br/> المواد الغذائية، ومواد التعبئة والتغليف.
               <br/> المعدات والتركيبات الفنية.
               <br/> شراء وإدارة العقارات، بما فيها الأراضي المخصصة للزراعة والغابات.
               <br/> ويجوز للشركة الاستحواذ على شركات محلية وأجنبية بجميع أنواعها، أو إقامة شراكة معها، والقيام بأعمال تساعد على تحقيق أهداف الشركة.
               <br/>
               <br/> (5) وصف مجموعات الأشخاص المتأثرين والبيانات أو فئات البيانات ذات الصلة:
               <br/>
               <br/> البيانات المتعلقة بالعملاء، والموظفين، والمتقاعدين، والموظفين لدى الشركات التابعة، والموظفين لدى شركات خارجية (مقاولي الباطن)، والموظفين التعاقديين، والمتقدمين إلى الوظائف، والمبتكرين الخارجيين أو الورثة، على التوالي، والموردين ومزودي الخدمات، والعملاء الخارجيين، والمستهلكين، والمتطوعين في اختبارات المستهلكين، وزوار موقع الشركات، والمستثمرين، والمحللين الماليين، والمساهمين – بالقدر اللازم لتحقيق الأغراض المحددة في النقطة رقم (4) من الفقرة رقم 7.
               <br/>
               <br/> (6) المستلمون أو فئات المستلمين ممن قد يتم الإفصاح لهم عن البيانات:
               <br/>
               <br/> السُّلطات العامة، وصناديق التأمين الصحي، ورابطة مسؤولية أصحاب العمل في حال وجود لوائح قانونية ذات صلة، والمتعاقدون الخارجيون وفقًا للفقرة رقم (11) من قانون حماية البيانات الفيدرالي، ومزودو الخدمات الخارجيون، ورابطة متقاعدي هنكل، والشركات التابعة والإدارات الداخلية، وذلك لتحقيق الأغراض المحددة في النقطة رقم (4) من الفقرة رقم (7).
               <br/>
               <br/> (7) الفترات المنتظمة لحذف البيانات:
               <br/>
               <br/> أصدر المُشرِّعون مجموعةً كبيرة من مهام وفترات التخزين. ويتم حذف البيانات كإجراء اعتيادي بعد انتهاء هذه الفترات. ويتم حذف البيانات غير المتأثرة بمثل هذه الشروط إذا لم تعد الأغراض المحددة في النقطة رقم (4) من الفقرة رقم (7) موجودة.
               <br/>
               <br/> 8) نقل البيانات المخطط لها إلى دول ثالثة:
               <br/>
               <br/> كجزء من نظام معلومات الموارد البشرية العالمي، يتم إتاحة بيانات الموظفين للتنفيذيين الإداريين المحددين في دول ثالثة.  وسيتم إبرام اتفاقيات حماية البيانات المتطابقة مع الشركات المعنية وفقًا لمعايير الاتحاد الأوروبي.
               <br/>
               <br/>
               <br/> 9. استخدام البرامج الإضافية لوسائل التواصل الاجتماعي
               <br/> قد يتم تضمين البرامج الإضافية لشبكات التواصل الاجتماعي - فيسبوك وتويتر- في صفحات الموقع الإلكتروني الخاصة بنا. ويتم تقديم الخدمات ذات الصلة من قِبل شركتي فيسبوك وتويتر على التوالي ("المزودون").
               <br/>
               <br/> يتم إدارة وتشغيل موقع التواصل الاجتماعي فيسبوك من قِبل شركة فيسبوك، 1601، إس. مقاطعة كاليفورنيا، بالو ألتو، سي إيه 94304، الولايات المتحدة الأمريكية ("فيسبوك"). ولإلقاء نظرة عامة على البرامج الإضافية الخاصة بشبكة فيسبوك وشكلها، يُمكنكم زيارة الرابط: https://developers.facebook.com/docs/plugins
               <br/>
               <br/> يتم إدارة وتشغيل موقع التواصل الاجتماعي توتير من قِبل شركة توتير، 1355 شارع ماركت، جناح 900، سان فرانسيسكو، سي إيه 94103، الولايات المتحدة الأمريكية ("فيسبوك"). ولإلقاء نظرة عامة على البرامج الإضافية الخاصة بشبكة تويتر وشكلها، يُمكنكم زيارة الرابط: https://about.twitter.com/en/who-we-are/brand-toolkit
               <br/>
               <br/> لزيادة الحماية المقدمة لبياناتك عند زيارة صفحات الويب خاصتنا، يتم تطبيق هذه البرامج الإضافية في شكل أزرار بنقرتين "2-click buttons". يضمن هذا الشكل من التكامل -عند الوصول من داخل موقعنا الإلكتروني الذي يحتوي على مثل هذه البرامج الإضافية- أنك غير متصل تلقائيًّا بالخوادم الخاصة بالمزودين. وفي حال تفعيل البرامج الإضافية فقط يتم إصدار إذن بنقل البيانات، ثم يقوم المتصفح بإنشاء رابط مباشر بالخوادم الخاصة بالمزودين. وبعد ذلك يتم نقل محتوى البرامج الإضافية المختلفة من قِبل المزود المعني مباشرةً إلى المتصفح الخاص بكِ ومن ثم عرضه على شاشتكِ.
               <br/>
               <br/> يقوم البرنامج المساعد بإخطار المزود عن صفحات الموقع الإلكتروني التي وصلتِ إليها. وفي حال تسجيل الدخول إلى حساب الفيسبوك أو التويتر الخاص بكِ أثناء زيارتكِ لموقعنا الإلكتروني، يتمكن المزود من جذب اهتمامكِ، أي المعلومات التي تصلي إليها بالإضافة إلى حساب المُستخدم الخاص بك. وعند استخدام أيٍّ من وظائف البرامج الإضافية، مثل (النقر على زر "أعجبني"، أو ترك تعليق)، سينقل المتصفح أيضًا هذه المعلومات مباشرةً إلى المزود للاحتفاظ بها.
               <br/>
               <br/> لمزيد من المعلومات حول جمع واستخدام البيانات عن طريق الفيسبوك وتويتر، وكذلك الحقوق والإمكانيات المُتاحة لكِ لحماية خصوصيتكِ في هذه الظروف، يمكنكِ الاطِّلاع على حماية البيانات/ إرشادات الخصوصية الصادرة عن المزودين:
               <br/>
               <br/> حماية البيانات/ إرشادات الخصوصية الصادرة عن فيسبوك:http://www.facebook.com/policy.php
               <br/>
               <br/> حماية البيانات/ إرشادات الخصوصية الصادرة عن تويتر: https://twitter.com/privacy
               <br/>
               <br/> لمنع موقعي فيسبوك وتويتر من ربط زيارتكِ لموقعنا الإلكتروني بحساب المُستخدم الخاص بكِ مع هؤلاء المزودين؛ ستحتاجين إلى تسجيل الخروج من حساب المستخدم المعني قبل الدخول إلى صفحات الويب خاصتنا.
            </div>
            <Footer/>
        </div>
    )
}
