import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const Terms = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"#1B1A19", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Terms of Use</title></Helmet>
            <div style={{maxWidth:800, marginBottom:40}}>
                <p style={{fontWeight:"bold"}}>Terms of Use</p>
                Due to ongoing technical innovations and changes of the relevant legal framework the following Terms of Use need to be changed and/or amended by us from time to time. We therefore ask the user to review these Terms of Use before each visit to this website and to take into account any changes and/or amendments.
                <p style={{fontWeight:"bold"}}>Introduction</p>
                This website is brought to you by Henkel Jebel Ali FZCO, Dubai Silicon Oasis, Block F, Dubai, UAE. The information available on this website about Henkel Jebel Ali and Henkel AG & Co. KGaA (hereinafter referred to as "Henkel"), affiliated companies of Henkel and third parties has been put together with utmost care. However, we are unable to guarantee the completeness and correctness of the information. Henkel does not assume any liability for any mistakes in the contents of this website. Forward-looking statements made on this website have been given according to the best of our knowledge and belief. However, the results actually achieved by Henkel may differ greatly from these forward-looking statements, because they depend on a whole group of factors of a competitive and macroeconomic nature that are in some cases beyond the control of Henkel. Without prejudice to any legal obligations to amend forward-looking statements, Henkel has no intention of constantly updating all forward-looking statements contained in this website.
                <br/>By accessing this site, you accept without restriction or reservation the following General Terms of Use of the Website that you can view or print out.
                <br/>THIS WEBSITE AND THE INFORMATION CONTAINED HEREIN IS NOT INTENDED OR APPROVED FOR USE INSIDE THE UNITED STATES OF AMERICA OR U.S. CITIZENS OR RESIDENTS. SAID PERSONS ARE KINDLY ASKED TO CONTACT THEIR LOCAL HENKEL WEBSITE OR WEBSITES OF US- AFFILIATED COMPANIES OF HENKEL.
                <p style={{fontWeight:"bold"}}>General Terms of Use of the Website</p>
                <p style={{fontWeight:"bold"}}>1. Copyright Law</p>
                The pages of our site (contents, structure) are protected by copyright. In particular, any reproductions, adaptations, translations, storage and processing in other media, including storage or processing by electronic means, enjoy copyright protection. Any exploitation in whole or in part thereof shall require the prior written consent of Henkel AG & Co. KGaA. Any reproduction of information or data, in particular the use of texts or parts thereof or image materials (except for Henkel press photos) or any other exploitation or dissemination, requires Henkel's prior written consent. The rights of dissemination and reproduction are held by Henkel.
                <br/>Henkel press photos may be used for editorial purposes only. Henkel press photos that are reproduced and/or electronically modified for editorial purposes must bear the copyright notice "© 2021 Henkel AG & Co. KGaA . All rights reserved".
                <br/>"© 2021 Henkel Jebel Ali FZCO. All rights reserved".
                <br/>Reprinting is free of charge, however, we request a copy for our files.
                <p style={{fontWeight:"bold"}}>2. Trademarks</p>
                The Henkel Oval logo and Claim “Henkel - Exellence is our Passion”, and all product names and/or product get-ups on these pages are registered trademarks of Henkel AG & Co. KGaA, its subsidiaries, affiliates or licensors. Any unauthorized use or abuse of these trademarks is expressly prohibited and constitutes a violation of trademark law, copyright law, other intellectual property rights or unfair competition law.
                <p style={{fontWeight:"bold"}}>3. Disclaimer for Third Party Websites</p>
                a. The pages of this website contain links (i.e. "hyperlinks") to other websites that are operated by third parties and whose contents are not known to Henkel. Henkel merely facilitates access to such websites and assumes no responsibility whatsoever for their contents. Our links to third party websites are merely intended to make navigating easier for you. Statements shown on linked pages are not made our own. We rather explicitly dissociate from any and all contents of any and all third party pages linked on the pages of our website. In particular, we do not assume any liability for any breaches of statutory provisions or infringements of third party rights that occur on such pages.
                <br/>b. For websites to which hyperlinks are provided from the Henkel website, the owners of these websites are solely responsible for the content of these pages as well as for any sale of the products offered thereon and for the handling of any orders relating to the same.
                <br/>c. Henkel does not assume any liability for any infringement of any copyrights, trademarks or other intellectual property or personality rights that occurs on a page accessed by a hyperlink.
                <br/>d. In the event of an order or any other legal declaration in respect of a transaction, a contract is made solely between the user and the owner of the respective website or offering party or person presented therein, under no circumstances, however, between Henkel and the user. Please note the general business conditions of the respective supplier on the hyperlinked website.
                <br/>e. This disclaimer is valid for all links displayed on the henkel.com website and for any contents of websites to which the user is directed via such links.
                <p style={{fontWeight:"bold"}}>4. General Disclaimer</p>
                Any liability of Henkel for damages arising out of the use of this website – irrespective of the legal cause, including tort – is limited to damages that are caused by intent or gross negligence. To the extent that there be a mandatory liability of Henkel as a result of a breach of material contractual duties, the total amount of any claim for damages is limited to the foreseeable damages. This shall not affect Henkel's liability under applicable Product Liability law or under any warranties given. The aforesaid limitations of liability also do not apply in the event of harm to life, bodily injury or harm to health of a person.
                <br/>Henkel employs great efforts to keep Henkel websites free from viruses, however, we are unable to guarantee any absence of viruses. For this reason, we recommend that care be taken to assure adequate protection against viruses (e.g. using virus scanners) before downloading documents and data.
                <br/>Henkel does not warrant any freedom from faults or errors of the services offered on the Henkel website nor their availability.
                <p style={{fontWeight:"bold"}}>5. Forecasts and Declarations of Intent</p>
                Forward-looking statements on this Internet site have been made according to the best of our knowledge and belief. However, the results actually achieved by Henkel may differ greatly from these forward-looking statements, because they depend on a whole group of factors of a competitive and macroeconomic nature that are in some cases beyond the control of Henkel. Without prejudice to any legal obligations to amend forward-looking statements, Henkel has no intention of constantly updating all forward-looking statements contained in this website.
                <p style={{fontWeight:"bold"}}>6. Henkel Products</p>
                The brands / products shown on this Internet website are examples of the brands / products available from companies of Henkel worldwide. Henkel does not warrant that a brands / product shown is also available in your country.
                <p style={{fontWeight:"bold"}}>Miscellaneous</p>
                These General Terms of Use are governed by and construed in accordance with the laws of the Federal Republic of Germany without regard to the UN Convention on Contracts for the International Sale of Goods the application of which Convention to these General Terms of Use is hereby expressly excluded. To the extent permitted by applicable law, legal venue for all disputes relating to this website lies with Düsseldorf, Germany. Should any provision of these General Terms of Use be or become invalid, this shall not affect the validity of the remaining provisions.
            </div>
            <Footer/>
        </div>
    )
}
