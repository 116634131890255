import React from 'react';
import {Route, Routes} from 'react-router';
import {Content} from "./components/en/Content";
import {Terms} from "./components/en/Rights";
import {Cookie} from "./components/en/Cookie";
import {Privacy} from "./components/en/Privacy";
import {Imprint} from "./components/en/Personal";
import {AEContent} from "./components/ae/Content";
import {AETerms} from "./components/ae/Internal";
import {AECookie} from "./components/ae/Cookie";
import {AEPrivacy} from "./components/ae/Privacy";
import {AEImprint} from "./components/ae/Personal";

export const useRoutes = () => {
    return (
        <Routes>
                <Route path="/" element={<Content/>} />
                <Route path="/en/" element={<Content/>} />
                <Route path="/en/policy" element={<Privacy/>} />
                <Route path="/en/terms" element={<Terms/>} />
                <Route path="/en/cookies" element={<Cookie/>} />
                <Route path="/en/imprint" element={<Imprint/>} />
                <Route path="/ae" element={<AEContent/>} />
                <Route path="/ae/policy" element={<AEPrivacy/>} />
                <Route path="/ae/terms" element={<AETerms/>} />
                <Route path="/ae/cookies" element={<AECookie/>} />
                <Route path="/ae/imprint" element={<AEImprint/>} />
        </Routes>
    );
};
