import Logo from '../../assets/logo.webp';
import {useState} from "react";
import {useNavigate} from "react-router";

export const Header = () => {
    const history = useNavigate();
    const toMain = () => {
        if (window.location.pathname === '/'){
            history(`/`);
            window.location.replace(`/`)
        }else if(window.location.pathname.includes('en')){
            history(`/en`);
        }else if(window.location.pathname.includes('ae')){
            history(`/ae`);
        }
    }
    const newPath = (lang) => {
        if (window.location.pathname === '/'){
            window.location.replace(`/${lang}`)
        }else if(window.location.pathname.includes('en')){
            const newURL = window.location.pathname.replace(/en/i, `${lang}`);
            window.location.replace(`${newURL}`);
        }else if(window.location.pathname.includes('ae')){
            const newURL = window.location.pathname.replace(/ae/i, `${lang}`);
            window.location.replace(`${newURL}`);
        }
    }
    return(
        <header>
            <img onClick={toMain} className={"logo"} src={Logo}/>
            <div className={"langs"}>
                <a onClick={()=>{
                    newPath('en');
                }} style={
                    window.location.pathname === '/' || window.location.pathname.includes('en') ?
                        {color:"#1B1A19", textDecoration:"none", fontWeight:"bold"} :
                        {color:"#1B1A1940", textDecoration:"none", fontWeight:"bold"}}>EN</a>
                <a onClick={()=>{
                    newPath('ae');
                }} style={
                    window.location.pathname.includes("ae") ?
                    {
                        fontWeight:"bold",
                        borderLeft: "1px solid #1B1A19",
                        color:"#1B1A19",
                        textDecoration:"none"
                    } :
                        {
                            fontWeight:"bold",
                            borderLeft: "1px solid #1B1A19",
                            color:"#1B1A1940",
                            textDecoration:"none"
                        }
                }>AR</a>
            </div>
        </header>
    )
}
