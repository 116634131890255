import nat1 from '../../lang/content/nat/n1.webp';
import {Card} from "./Card";
import {useState} from "react";
import {Footer} from "./Footer";
import {Helmet} from "react-helmet";
import {AllPalette} from "../../assets/AllPalette";
import {NatPalette} from "../../assets/NatPalette";
import {IntPalette} from "../../assets/IntPalette";
import Int1Palette from "../../assets/Int1Palette.webp";
import int2Palette from "../../assets/Multicare 1 AR.webp";
import nat1Palette from "../../assets/Nat1Palette.webp";
import nat2Palette from "../../assets/Naturals_2.webp";
import inv1 from "../../lang/content/inv/1.webp";
import inv2 from "../../lang/content/inv/2.webp";
import inv3 from "../../lang/content/inv/3.webp";
import inv4 from "../../lang/content/inv/4.webp";
import inv5 from "../../lang/content/inv/5.webp";
import inv6 from "../../lang/content/inv/6.webp";
import inv7 from "../../lang/content/inv/7.webp";
import inv8 from "../../lang/content/inv/8.webp";
import inv9 from "../../lang/content/inv/9.webp";
import inv10 from "../../lang/content/inv/10.webp";
import inv11 from "../../lang/content/inv/11.webp";
import inv12 from "../../lang/content/inv/12.webp";
import inv13 from "../../lang/content/inv/13.webp";
import inv14 from "../../lang/content/inv/14.webp";
import inv15 from "../../lang/content/inv/15.webp";
import inv16 from "../../lang/content/inv/16.webp";
import inv17 from "../../lang/content/inv/17.webp";
import inv18 from "../../lang/content/inv/18.webp";
import inv19 from "../../lang/content/inv/19.webp";
import inv20 from "../../lang/content/inv/20.webp";
import inv21 from "../../lang/content/inv/21.webp";
import inv22 from "../../lang/content/inv/22.webp";
import inv23 from "../../lang/content/inv/23.webp";
import inv24 from "../../lang/content/inv/24.webp";
import nat2 from "../../lang/content/nat/n2.webp";
import nat3 from "../../lang/content/nat/n3.webp";
import nat4 from "../../lang/content/nat/n4.webp";
import nat5 from "../../lang/content/nat/n5.webp";
import nat6 from "../../lang/content/nat/n6.webp";
import nat7 from "../../lang/content/nat/n7.webp";
import nat8 from "../../lang/content/nat/n8.webp";
import nat9 from "../../lang/content/nat/n9.webp";
import nat10 from "../../lang/content/nat/n10.webp";
import nat11 from "../../lang/content/nat/n11.webp";
import nat12 from "../../lang/content/nat/n12.webp";
import nat13 from "../../lang/content/nat/n13.webp";
import nat14 from "../../lang/content/nat/n14.webp";
import nat15 from "../../lang/content/nat/n15.webp";

export const AEContent = () => {
    const [defaultBrand, setDefaultBrand] = useState("all");
    const content = {
        "int": [
            {
                "label": "أسود",
                "key": "1-0",
                "image": inv1,
                "link": "https://sk-qr.com/PICC10/KV"
            },
            {
                "label": "أسود أزرق",
                "key": "1-1",
                "image": inv2,
                "link": "https://sk-qr.com/PICC11/KV"
            },
            {
                "label": "بني غامق",
                "key": "3-0",
                "image": inv3,
                "link": "https://sk-qr.com/PICC30/KV"
            },
            {
                "label": "شوكولا غامق",
                "key": "3-65",
                "image": inv4,
                "link": "https://sk-qr.com/PICC365/KV"
            },
            {
                "label": "ماهوغني غامق",
                "key": "3-68",
                "image": inv5,
                "link": "https://sk-qr.com/PICC368/KV"
            },
            {
                "label": "بني متوسط",
                "key": "4-0",
                "image": inv6,
                "link": "https://sk-qr.com/PICC40/KV",
            },
            {
                "label": "بني متوسط لامع",
                "key": "4-60",
                "image": inv7,
                "link": "https://sk-qr.com/PICC460/KV",
            },
            {
                "label": "أحمر غامق كثيف",
                "key": "4-88",
                "image": inv8,
                "link": "https://sk-qr.com/PICC488/KV",
            },
            {
                "label": "باذنجاني كثيف",
                "key": "4-89",
                "image": inv9,
                "link": "https://sk-qr.com/PICC489/KV",
            },
            {
                "label": "بني فاتح",
                "key": "5-0",
                "image": inv10,
                "link": "https://sk-qr.com/PICC50/KV",
            },
            {
                "label": "كستنائي متوسط",
                "key": "5-68",
                "image": inv11,
                "link": "https://sk-qr.com/PICC568/KV",
            },
            {
                "label": "أشقر غامق",
                "key": "6-0",
                "image": inv12,
                "link": "https://sk-qr.com/PICC60/KV",
            },
            {
                "label": "بني مارسالا",
                "key": "6-80",
                "image": inv13,
                "link": "https://sk-qr.com/PICC680/KV",
            },
            {
                "label": "أحمر كثيف",
                "key": "6-88",
                "image": inv14,
                "link": "https://sk-qr.com/PICC688/KV",
            },
            {
                "label": "أشقر متوسط",
                "key": "7-0",
                "image": inv15,
                "link": "https://sk-qr.com/PICC70/KV",
            },
            {
                "label": "أشقر متوسط بارد",
                "key": "7-1",
                "image": inv16,
                "link": "https://sk-qr.com/PICC71/KV",
            },
            {
                "label": "بروج ناعم",
                "key": "7-17",
                "image": inv17,
                "link": "https://sk-qr.com/PICC717/KV",
            },
            {
                "label": "نوجة متألق",
                "key": "7-65",
                "image": inv18,
                "link": "https://sk-qr.com/PICC765/KV",
            },
            {
                "label": "أشقر فاتح",
                "key": "8-0",
                "image": inv19,
                "link": "https://sk-qr.com/PICC80/KV",
            },
            {
                "label": "أشقر فاتح رمادي",
                "key": "8-1",
                "image": inv20,
                "link": "https://sk-qr.com/PICC81/KV",
            },
            {
                "label": "أشقر فاتح فائق",
                "key": "9-0",
                "image": inv21,
                "link": "https://sk-qr.com/PICC90/KV",
            },
            {
                "label": "أشقر فاتح فائق رمادي",
                "key": "9-1",
                "image": inv22,
                "link": "https://sk-qr.com/PICC91/KV",
            },
            {
                "label": "أشقر فضي بارد",
                "key": "10-1",
                "image": inv23,
                "link": "https://sk-qr.com/PICC101/KV",
            },
            {
                "label": "أشقر رمادي",
                "key": "10-2",
                "image": inv24,
                "link": "https://sk-qr.com/PICC102/KV",
            }
        ],
        "nat": [
            {
                "label": "أسود",
                "key": "1-0",
                "image": nat1,
                "link": "https://sk-qr.com/PNC10/KV",
            },
            {
                "label": "أسود أزرق",
                "key": "1-1",
                "image": nat2,
                "link": "https://sk-qr.com/PNC11/KV",
            },
            {
                "label": "بني غامق",
                "key": "3-0",
                "image": nat3,
                "link": "https://sk-qr.com/PNC30/KV",
            },
            {
                "label": "بني شوكولا",
                "key": "3-68",
                "image": nat4,
                "link": "https://sk-qr.com/PNC368/KV",
            },
            {
                "label": "بني متوسط",
                "key": "4-0",
                "image": nat5,
                "link": "https://sk-qr.com/PNC40/KV",
            },
            {
                "label": "بني فاتح",
                "key": "5-0",
                "image": nat6,
                "link": "https://sk-qr.com/PNC50/KV",
            },
            {
                "label": "أشقر غامق",
                "key": "6-0",
                "image": nat7,
                "link": "https://sk-qr.com/PNC60/KV",
            },
            {
                "label": "بني كرامتل",
                "key": "6-68",
                "image": nat8,
                "link": "https://sk-qr.com/PNC668/KV",
            },
            {
                "label": "أحمر جرانيت",
                "key": "6-88",
                "image": nat9,
                "link": "https://sk-qr.com/PNC688/KV",
            },
            {
                "label": "أشقر متوسط",
                "key": "7-0",
                "image": nat10,
                "link": "https://sk-qr.com/PNC70/KV",
            },
            {
                "label": "أشقر ذهبي متوسط",
                "key": "7-65",
                "image": nat11,
                "link": "https://sk-qr.com/PNC765/KV",
            },
            {
                "label": "أشقر فاتح",
                "key": "8-0",
                "image": nat12,
                "link": "https://sk-qr.com/PNC80/KV",
            },
            {
                "label": "أشقر رمادي فاتح",
                "key": "8-16",
                "image": nat13,
                "link": "https://sk-qr.com/PNC816/KV",
            },
            {
                "label": "أشقر فاتح فائق",
                "key": "10-0",
                "image": nat14,
                "link": "https://sk-qr.com/PNC100/KV",
            },
            {
                "label": "أشقر بيج فائق",
                "key": "10-4",
                "image": nat15,
                "link": "https://sk-qr.com/PNC104/KV",
            },
        ]
    }
    return(
        <div className={"content"}>
            <Helmet><title>!مستعدّة للتغيير؟اضغطي على العبوة و جرّبي اللون</title></Helmet>

            <h2 style={{textAlign:"right", width:"100%", maxWidth:"472px", color:"#1B1A19"}}>مستعدّة للتغيير؟<br/>
                ! اضغطي على العبوة و جرّبي اللون </h2>
            <div style={{marginTop:30, marginBottom:30, width:"100%", maxWidth:"470px", border:"1px solid #C4C4C4", background:"#E5E4E4", borderRadius:"100px", display:"flex"}}>
                <button
                    onClick={() => {setDefaultBrand("all")}}
                    style={defaultBrand === "all" ? {background:"white", border:"none", borderRadius:"100px", width:"100%"} : {background:"transparent", border:"none", width:"100%"}}><AllPalette fill={"#1B1A19"}/></button>
                <button
                    onClick={() => {setDefaultBrand("int")}}
                    style={defaultBrand === "int" ? {background:"white", border:"none", borderRadius:"100px", width:"100%"} : {background:"transparent", border:"none", width:"100%"}}><IntPalette fill={"#1B1A19"}/></button>
                <button
                    onClick={() => {setDefaultBrand("nat")}}
                    style={defaultBrand === "nat" ? {background:"white", border:"none", borderRadius:"100px", width:"100%"} : {background:"transparent", border:"none", width:"100%"}}><NatPalette fill={"#1B1A19"}/></button>
            </div>
            {
                defaultBrand === "int" || defaultBrand === "all" ?
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:32, width:"100vw", background: "rgba(124, 43, 135, 1) linear-gradient(180deg, #7C2B87 0%, #452566 100%)", paddingTop:20, paddingBottom:20}}>
                        <img src={Int1Palette} style={{maxWidth:390, minWidth:320, width:"100%"}} draggable={false}/>
                        <div className={"content"}>
                            <div className={"wrapper"}>
                                {
                                    content.int.map((item, index) => (
                                        <Card key={index} item={item} product={"int"}/>
                                    ))
                                }
                            </div>
                        </div>
                        <img src={int2Palette} style={{maxWidth:390, minWidth:320, width:"100%"}} draggable={false}/>
                    </div>: null
            }
            {
                defaultBrand === "nat" || defaultBrand === "all" ?
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:32, width:"100vw", background: "linear-gradient(180deg, #BCDA79 0%, #CCDE92 100%)", paddingTop:20, paddingBottom:20}}>
                        <img src={nat1Palette} style={{maxWidth:300, minWidth:280, width:"100%"}} draggable={false}/>
                        <div className={"content"}>
                            <div className={"wrapper"}>
                                {
                                    content.nat.map((item, index) => (
                                        <Card key={index} item={item} product={"nat"}/>
                                    ))
                                }
                            </div>
                        </div>
                        <img src={nat2Palette} style={{maxWidth:390, minWidth:320, width:"100%"}} draggable={false}/>
                    </div> : null
            }
            <Footer/>
        </div>
    )
}
